div.container
{
    align-items: center;
    display: flex;
    flex-direction: row;
    height: 100%;
    justify-content: flex-start;
    width: 100%;
}

div.color
{
    border: solid 1px darkgray;
    border-radius: 50%;
    height: 100%;
    overflow: hidden;
    width: 100%;
}

div.color.selected
{
    box-shadow: 5px 5px 8px darkgray;
}