/*
    Big List
*/

div.container {
    align-items: center;
    display: flex;
    flex: 0 1 100%;
    flex-direction: column;
    justify-content: flex-end;
    overflow: hidden;
    width: 100%;
}

div.header {
    align-items: center;
    display: flex;
    flex: 0 1 10%;
    flex-direction: row;
    justify-content: flex-start;
    width: 75%;
}

label.title {
    color: #455A64;
    flex: 0 1 7%;
    font-size: 2vmin;
    margin-right: 1vmin;
}

div.divider {
    border-left: 1px;
    height: 100%;
    margin-right: 1vmin;
}

.search-icon {
    font-size: 2vmin;
    margin-right: 1vmin;
}

input.search-bar {
    background-color:transparent;
    border: none;
    outline: none;
    flex: 0 1 20%;
    font-size: 2vmin;
    height: 40%;
    width: 1%;
}

input.search-bar:focus {
    border-bottom: 1px solid #979797;
    transition: border-bottom 1s;
}

button.create-new {
    background-color: #FFFFFF;
    border-radius: 4.25px;
    border-style: none;
    flex: 0 1 12%;
    font-size: 2vmin;
    height: 45%;
    justify-self: flex-end;
    margin-left: auto;
    margin-right: 1vmin;
    white-space: nowrap;
    box-shadow: 0 6px 9px 0 rgba(144,164,183,0.22);
}

div.list {
    align-items: stretch;
    display: flex;
    flex: 0 1 90%;
    flex-direction: column;
    overflow-y: scroll;
    width: 75%;
}

div.item {
    background-color: #FFFFFF;
    border-radius: 4px;
    box-shadow: 0 6px 9px 0 rgba(144,164,183,0.22);
    display: flex;
    flex: 0 0 15%;
    flex-direction: row;
    margin-bottom: 1vmin;
    overflow: hidden;
    width: 100%;
}

div.vertical-bar {
    background-color: #026FC2;
    flex: 0 1 1%;
}

div.item_header-container {
    align-items: flex-start;
    display: flex;
    flex: 0 1 40%;
    flex-direction: column;
    justify-content: center;
    justify-self: flex-end;
    margin-left: 1%;
}

label.item_title-label {
    font-size: 3vmin;
    color: #3B3F48;
	font-weight: 500;
}

label.item_subtitle-label {
    font-size: 2.5vmin;
    color: #8A96A0;
}

div.item_body-container
{
    display: flex;
    flex: 0 1 58%;
    flex-direction: row;
    margin-left: 1%;
}