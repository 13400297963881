div.container
{
    display: flex;
    flex: 0 1 100%;
    flex-direction: column;
    height:99%;
    margin-right: auto;
    margin-top: 1vmin;
}

div.main-container
{
    align-items: center;
    display: flex;
    flex: 0 1 25%;
    justify-content: flex-start;
    width: 100%;
    font-size: 1.75vmin;
}

div.detail-container
{
    flex: 0 1 50%;
    width: 100%;
}