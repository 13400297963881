/*
    a generic side menu that contains navgation buttons
*/

div.container
{
    align-items: stretch;
    background-color: #F5F5F5;
    box-shadow: 1px 0 0 0 rgba(218,218,218,0.5);
    display: flex;
    flex: 0 0 16%;
    flex-direction: column;
    height: 100%;
    justify-content: flex-start;
}

div.item
{
    align-items: center;
    background-color: transparent;
    display: flex;
    flex: 0 1 6%;
    flex-direction: row;
    height: 4%;
    justify-content: flex-start;
    margin-top: 1vmin;
}

div.item.selected
{
    background-color: #DAE5EE;
}

div.vertical-bar
{
    flex: 0 1 3%;
    background-color: #026FC2;
    height: 100%;
    opacity: 0;
}

div.vertical-bar.selected
{
    opacity: 1;
}

label.title
{
    justify-self: flex-end;
    color: #3B3F48;
    flex: 0 1 90%;
    font-weight: 500;
    font-size: 2vmin;
    margin-left: auto;
}

label.title[disabled]
{
    color: gray;
    text-decoration: line-through;
}

label.title.selected
{
    color: #026FC2
}