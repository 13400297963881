/*
Examiner Test Page
*/

div.card {
    align-items: center;
    background-color: white;
    border: solid 1px rgb(181, 191, 191, 0.26);
    border-radius: 1vmin;
    box-shadow: rgb(181, 191, 191, 0.26) 3px 3px;
    display: flex;
    flex-direction: column;
    height: 95%;
    justify-content: space-evenly;
    width: 95%;
}

div.card__header {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 1 8%;
    flex-direction: row;
    justify-content: flex-start;
    min-height: 0;
}

label.name {
    color: #455A64;
    font-size: 2vmin;
    margin: auto 0 auto 2%;
}

label.session-id-label {
    color: #455A64;
    font-size: 2.5vmin;
    font-weight: 500;
    margin: auto 0 auto 2%;
}

div.session-id-border {
    border: solid 1px #EBEBEB;
    border-radius: 0.5vmin;
    margin: auto 0 auto 2%;
}

label.session-id {
    color: #455A64;
    font-size: 2.5vmin;
    font-weight: 300;
    margin: 2%;
    white-space: nowrap;
}

label.item-label-label {
    color: #455A64;
    font-size: 2.5vmin;
    font-weight: 500;
    margin: auto 0 auto 2%;
}

div.item-label-border {
    border: solid 1px #EBEBEB;
    border-radius: 0.5vmin;
    margin: auto 0 auto 2%;
}

label.item-label {
    color: #455A64;
    font-size: 2.5vmin;
    font-weight: 300;
    margin: 2%;
    white-space: nowrap;
}

button.end-test {
    align-self: center;
    background-color: #026FC2;
    border-radius: 0.5vmin;
    color: #FFFFFF;
    flex: 0 1 10%;
    font-size: 2.5vmin;
    font-weight: 500;
    height: 80%;
    justify-self: right;
    margin: auto 2% auto 1%;
    text-align: center;
}

button.end-test.next-test {
    margin: auto 0% auto auto;
}

hr.card__line {
    border: 1px solid #E8E8E8;
    width: 99%;
}

div.card__body {
    align-self: center;
    flex: 0 1 68%;
    min-height: 0;
    width: 95%;
}

div.card__controls-container {
    align-items: center;
    display: flex;
    flex: 0 1 20%;
    flex-direction: row;
    justify-content: center;
    min-height: 0;
    width: 95%;
}

div.score-container--buttons {
    align-content: center;
    align-items: center;
    display: flex;
    flex: 0 1 25%;
    flex-direction: row;
    flex-wrap: wrap;
    height: 98%;
    justify-content: flex-start;
    margin-left: auto;
    margin-right: auto;
}

button.score-button {
    background: white;
    border: solid 1px darkgray;
    border-radius: 10%;
    color: #8c8c8c;
    font-size: 2vmin;
    height: 100%;
    overflow: hidden;
    width: 100%;
}

button.score-button.selected {
    background: #026FC2;
    color: white;
}

div.score-container--text {
    align-items: center;
    display: flex;
    flex: 0 1 8%;
    flex-direction: column;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
}

label.input-label {
    color: #46606D;
    font-size: 2vmin;
}

input.score-input {
    border: 0.2vmin solid #C8D2DF;
    border-radius: 0.5vmin;
    background-color: #FFFFFF;
    color: #C3C5C5;
    font-size: 4vmin;
    text-align: center;
    width: 100%;
}

input.response-input {
    border: 0.2vmin solid #C8D2DF;
    border-radius: 0.2vmin;
    background-color: #FFFFFF;
    color: #C3C5C5;
    font-size: 4vmin;
    text-align: center;
    width: 100%;
}

div.navigation-container {
    display: flex;
    flex: 0 1 5%;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

div.navigation-container.left {
    justify-self: flex-start;
    margin-right: auto;
}

div.navigation-container.right {
    justify-self: flex-end;
    margin-left: auto;
}

button.navigation-button {
    background-color: transparent;
    border: none;
    color: #026FC2;
    font-size: 3vmin;
}

button.navigation-button:disabled {
    opacity: 0.5;
}

.navigation-icon-spacing--left {
    margin-right: 2%;
}

.navigation-icon-spacing--right {
    margin-left: 2%;
}

div.examinee-preview-container {
    background-color: #FFFFFF;
    border: 1px solid #EBEBEB;
    border-radius: 0.5vmin;
    box-shadow: 0 5px 8px 0 rgba(0,0,0,0.09);
    flex: 0 1 25%;
    height: 95%;
    margin-left: auto;
    margin-right: auto;
}

div.note-container {
    display: flex;
    flex: 0 1 20%;
    flex-direction: column;
    height: 95%;
    margin-left: auto;
    margin-right: auto;
}

textarea.note {
    border: 0.2vmin solid #C8D2DF;
    border-radius: 0.5vmin;
    height: 100%;
    resize: none;
    width: 100%;
}

div.response-container {
    display: flex;
    flex: 0 1 20%;
    flex-direction: column;
    height: 95%;
    margin-left: auto;
    margin-right: auto;
}

textarea.response {
    border: 0.2vmin solid #C8D2DF;
    border-radius: 0.5vmin;
    height: 100%;
    resize: none;
    width: 100%;
}