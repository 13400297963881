div.container-a
{
    align-items: center;
    background: hsla(47, 25%, 89%, 1);
    background: linear-gradient(0deg, hsla(47, 25%, 89%, 1) 1%, hsla(45, 2%, 55%, 1) 100%);
    background: -moz-linear-gradient(0deg, hsla(47, 25%, 89%, 1) 1%, hsla(45, 2%, 55%, 1) 100%);
    background: -webkit-linear-gradient(0deg, hsla(47, 25%, 89%, 1) 1%, hsla(45, 2%, 55%, 1) 100%);
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#EAE7DC", endColorstr="#8E8D8A", GradientType=1 );
    background-image: url('../assets/img/trees.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center bottom 35%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    width: 100%;
}

div.container-b
{
    display: flex;
    flex: 0 1 60%;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 100%;
}

div.container-c
{
    display: flex;
    flex: 0 1 30%;
    height: 100%;
    justify-content: center;
}

label.logo
{
    color: #5F6366;
    font-family: Philosopher;
    font-size: 19vmin;
    text-shadow: 2px 2px #99CED3;
}

div.card
{
    background-color: #5F6366cc;
    border: 0.5vmin solid #4D6D9A;
    border-radius: 3vmin;
    border-top-left-radius: 2vmin;
    border-bottom-right-radius: 2vmin;
    box-shadow: 0 20px 40px 0 rgba(0,0,0,0.2);
    display: flex;
    flex: 0 1 100%;
    flex-direction: column;
    height: 100%;
    justify-content: space-around;
}

div.input-container
{
    align-items: center;
    display: flex;
    flex: 0 1 40%;
    flex-direction: column;
    justify-content: space-evenly;
    width: 100%;
}

input.input
{
    border: 0.3vmin solid #4D6D9A;
    border-radius: 5px;
    background-color: #efefef;
    color: #5F6366;
    flex: 0 1 30%;
    font-size: 3vmin;
    margin-bottom: 1vmin;
    width: 80%;
}

input.input::placeholder
{
    color: #5F636680;
}

input.input:focus
{
    outline: none;
}

div.button-container
{
    align-items: center;
    display: flex;
    flex: 0 1 30%;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}

button.button
{
    border: 0.4vmin solid #5F6366cc;
    border-radius: 2vmin;
    border-top-right-radius: 1vmin;
    border-bottom-left-radius: 1vmin;
    background-color: #99CED3;
    color: #5F6366;
    height: 40%;
    width: 60%;
}

label.button-label
{
    font-size: 4vmin;
}

div.action-container
{
    display: flex;
    flex: 0 1 5%;
    justify-content: space-around;
    justify-self: flex-end;
    width: 100%;
}

label.action
{
    color: #EDB5BF;
    font-size: 2vmin;
}