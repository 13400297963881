/*
The timer 'widget' on the examiner's test page
*/

div.container
{
    align-items: stretch;
    border: solid 2px #C8D2DF;
    border-radius: 1vmin;
    display: flex;
    flex: 0 1 6%;
    flex-direction: column;
    height: 95%;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
}

div.time-container
{
    flex: 0 1 40%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}

label.time-label
{
    color: #455A64;
    font-size: 3vmin;
    font-weight: 300;
    max-height: 100%;
}

div.button-container
{
    align-items: stretch;
    display: flex;
    flex: 0 1 60%;
    flex-direction: column;
    justify-content: space-evenly;
}

button.reset-button
{
    background-color: #bbbbbb;
    border-color: #C8D2DF;
    border-radius: 0 0 0 2px;
    border-style: solid;
    border-width: 1px 1px 0px 0px;
    box-shadow: none;
    color: #FFFFFF;
    flex: 0 1 50%;	
    font-size: 2.5vmin;
}

button.start-stop-button
{
    background-color: #2e8b57;
    border-color: #C8D2DF;
    border-radius: 0 0 2px 0;
    border-style: solid;
    border-width: 1px 0px 0px 0px;
    box-shadow: none;
    color: #FFFFFF;
    flex: 0 1 50%;
    font-size: 1.5vmin;
    font-weight: 500;
    font-size: 2.5vmin;
}

button.start-stop-button.running
{
    background-color: #8b0000;
}