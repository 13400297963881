div.container
{
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
}

div.upload-container
{
    flex: 0 1 10%;
}

div.size-container
{
    flex: 0 1 50%;
}