/*
    a commonly used interface framework
*/

div.container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
}

div.subcontainer-a {
    display: flex;
    flex: 0 1 92%;
    flex-direction: row;
    max-height: 92%;
    width: 100%;
}

div.subcontainer-b {
    align-items: center;
    display: flex;
    flex: 0 1 100%;
    flex-direction: column;
    height: 100%;
    justify-content: center;
}

div.subcontainer-c {
    align-items: center;
    display: flex;
    flex: 0 1 95%;
    flex-direction: column;
    justify-content: center;
    max-height: 95%;
    width: 100%;
}