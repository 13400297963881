/*
    examiner's examinee test history page
*/

button.ant-btn:disabled
{
    color: rgba(0, 0, 0, 0.6);
}

div.panel-header__container
{
    display: flex;
    flex-direction: row;
    font-size: 2vmin;
    justify-content: space-between;
}

div.panel-header_record-label
{
    flex: 0 1 25%;
}

div.panel-header_test-name
{
    flex: 0 1 25%;
}

div.panel-header_test-date
{
    flex: 0 1 10%;
}

div.panel-header_edit-buttons
{
    display: flex;
    flex: 0 1 10%;
    justify-content: flex-end;
}

label.panel-header__label
{
    white-space: nowrap;
}

div.panel-header_button
{
    flex: 0 1 30%;
}

div.panel_body-container
{
    display: flex;
    flex-direction: row;
}

div.panel_container-a
{
    display: flex;
    flex: 0 1 60%;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    margin-right: 1vmin;
}

div.panel_container-b
{
    display: flex;
    flex: 0 1 40%;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    margin-right: 1vmin;
}

div.panel_container-c
{
    flex: 0 1 50%;
    width: 100%;
    margin-bottom: 1vmin;
}

label.panel_container-c__label
{
    font-size: 1.5vmin;
    font-weight: bold;
}

textarea.data
{
    font-size: 1.5vmin;
    height: 100%;
    width: 100%;
}