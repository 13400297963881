div.container
{
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
}

div.varkey-container
{
    display: flex;
    flex: 0 1 30%;
    flex-direction: row;
    justify-content: center;
}

label.varkey
{
    flex: 0 1 auto;
}

div.varkey_input
{
    flex: 0 1 auto;
    height: 95%;
    margin-left: 0.5vmin;
    margin-right: 0.25vmin;
}