/*
Dashboard nav. this is the navigation bar across the top of the window.
*/

div.nav-container { 
    align-items: center;
    background-color: #026FC2;
    flex: 0 0 8%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

div.nav-button__container {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 60%;
    justify-content: space-evenly;
}

.nav-button__link {
    align-items: center;
    background-color: #026FC2;
    display: flex;
    flex-direction: row;
    height: 60%;
    justify-content: center;
    margin-left: 3vmin;
    text-decoration: none;
}

div.nav-button__link[disabled]
{
    color: gray;
    text-decoration: line-through;
}

div.nav-button-underline {
    align-self: flex-end;
    background-color: white;
    height: 0.25vmin;
    opacity: 0;
    width: 45%;
}

div.nav-button-underline-visible {
    opacity: 1;
}

.nav-button__icon {
    font-size: 2.5vmin;
}

label.nav-button__label {
    color: #FFFFFF;
    font-size: 2.5vmin;
    margin-left: 1vmin;
}

label.nav-button__label[disabled]
{
    color: gray;
    text-decoration: line-through;
}

div.user-menu__container {
    align-items: center;
    display: flex;
    flex: 0 1 20%;
    flex-direction: row;
    height: 60%;
    justify-content: flex-end;
    justify-self: flex-end;
    margin-left: auto;
    margin-right: 1vmin;
}

img.user-avatar {
    height: 4vmin;
    margin-right: 1vmin;
    width: 4vmin;
}

label.dropdown__label {
    flex: 0 1 75%;
    font-size: 2vmin;
    margin-right: 1vmin;
}

.caret-down-icon {
    font-size: 2vmin;
}