/*
    the card and it's contents of an info page
*/

/* TODO: use attr() for height-x when it supports parameters other than 'content' */

div.card
{
    align-items: center;
    background-color: #FFFFFF;
    border-radius: 6px;
    box-shadow: 0 6px 9px 0 rgba(144,164,183,0.22);
    display: flex;
    flex-direction: column;
    height: 95%;
    width: 95%;
}

div.header
{
    align-items: center;
    display: flex;
    flex: 0 1 15%;
    flex-direction: row;
    justify-content: flex-end;
    width: 95%;
}

label.name
{
    color: #455A64;
    font-size: 3vmin;
    margin-right: auto;
}

div.button-container
{
    align-items: center;
    display: flex;
    font-size: 2vmin;
    flex: 0 1 6%;
    flex-direction: column;
    height: 60%;
    justify-content: center;
    justify-self: flex-end;
    margin-right: 1vmin;
}

label.header-button-label
{
    color: #455A64;
    font-size: 1vmin;
}

hr.horizontal-line
{
    border: 1px solid #E8E8E8;
    width: 95%;
}

div.info-container
{
    align-content: space-around;
    align-items: center;
    display: flex;
    flex: 0 1 87%;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    overflow: hidden;
    width: 95%;
}

div.break
{
    flex: 0 1 100%;
    height: 0%;
}

div.item-container
{
    align-items: flex-start;
    display: flex;
    flex: 0 1 20%;
    flex-direction: column;
    height: 10%;
    justify-content: space-between;
    margin: 0.5vmin;
}

div.item-container[justify-content_flex-start]
{
    justify-content: flex-start;
}

div.item-container[scroll-horizontal]
{
    overflow-x: auto;
}

div.item-container[scroll-vertical]
{
    overflow-y: auto;
}

div.item-container[height-5] 
{
    height: 5%;
}

div.item-container[height-10]
{
    height: 10%;
}

div.item-container[height-15]
{
    height: 15%;
}

div.item-container[height-20]
{
    height: 20%;
}

div.item-container[height-30]
{
    height: 30%;
}

div.item-container[height-40]
{
    height: 40%;
}

div.item-container[height-50]
{
    height: 50%;
}

div.item-container[height-60]
{
    height: 60%;
}

div.item-container[height-70]
{
    height: 70%;
}

div.item-container[height-80]
{
    height: 80%;
}

div.item-container[height-90]
{
    height: 90%;
}

div.item-container[height-100]
{
    height: 100%;
}

div.item-container[width-5]
{
    flex: 0 1 5%;
}

div.item-container[width-10]
{
    flex: 0 1 10%;
}

div.item-container[width-15]
{
    flex: 0 1 15%;
}

div.item-container[width-20]
{
    flex: 0 1 20%;
}

div.item-container[width-30]
{
    flex: 0 1 30%;
}

div.item-container[width-40]
{
    flex: 0 1 40%;
}

div.item-container[width-50]
{
    flex: 0 1 50%;
}

div.item-container[width-60]
{
    flex: 0 1 60%;
}

div.item-container[width-70]
{
    flex: 0 1 70%;
}

div.item-container[width-80]
{
    flex: 0 1 80%;
}

div.item-container[width-90]
{
    flex: 0 1 90%;
}

div.item-container[width-100]
{
    flex: 0 1 100%;
}

label.item-name
{
    color: #354052;
    font-size: 2vmin;
    opacity: 0.5;
}

input.item-string
{
    background-color: transparent;
    border: none;
    color: #354052;
    font-size: 2.5vmin;
}

hr.item-underline
{
    border: 1px solid #E6EAEE;
    border-radius: 1vmin;
    width: 99%;
}

.date-picker
{
    width: 100%;
    height: 3vmin;
}

.item-select
{
    width: 100%;
    height: 3vmin;
}

div.item-content-container
{
    flex: 0 1 85%;
    width: 95%;
}

div.item-subcontainer-row
{
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
}