/*
    a generic editable table
*/

div.container {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: flex-start;
    width: 100%;
}

div.header-container {
    align-items: center;
    display: flex;
    flex: 0 1 20%;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
}

label.column-title {
    font-size: 1.5vmin;
    font-weight: bold;
}

div.body-container {
    display: flex;
    flex: 0 1 80%;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    overflow-x: hidden;
    overflow-y: scroll;
    width: 100%;
}

div.row {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
}

div.row.colored {
    background-color: #F5F5F5;
}

div.cell {
    align-items: center;
    display: flex;
    flex-direction: row;
    font-size: 1.5vmin;
    justify-content: center;
}

input.cell-string {
    background-color: transparent;
    border: none;
    color: #354052;
    font-size: 2vmin;
}

textarea.cell {
    background-color: transparent;
    border: none;
    color: #354052;
    font-size: 2vmin;
    width: 100%;
}