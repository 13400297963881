/*
    spreadsheet for editing/viewing data tables
*/

div.scroll-container
{
    /* border: solid 1px #EBEBEB; */
    /* border-radius: 0.5vmin; */
    font-size: 1.5vmin;
    height: 100%;
    width: 100%;
    overflow: scroll;
}

div.flex-container
{
    display: flex;
    /* min-height: min-content; */
    min-width: 100%;
}

div.column
{
    background-color: white;
    display: flex;
    flex: 0 1 10%;
    flex-direction: column;
    height: 110%;
}

div.column.row-label
{
    background-color: whitesmoke;
    font-weight: bold;
    position: -webkit-sticky; /* safari */
    position: sticky;
    left: 0;
    z-index: 2;
}

div.cell
{
    align-items: center;
    background-color: transparent;
    display: flex;
    flex: 0 1 5%;
    height: 2vmin;
    justify-content: center;
    min-height: 2vmin;
    text-align: center;
    width: 100%;
    white-space: nowrap;
    /* border: 0.1vmin solid lightgray; */
    /* border-radius: 0.25vmin; */
}

div.cell.column-label
{
    background-color: whitesmoke;
    font-weight: bold;
    position: -webkit-sticky; /* safari */
    position: sticky;
    top: 0;
    z-index: 1;
}

div.cell.corner
{
    background-color: white;
    position: -webkit-sticky; /* safari */
    position: sticky;
    top: 0;
    z-index: 3;
}

div.cell.selected
{
    background-color: #026FC233;
}

div.cell[bool]
{
    border: 0.1vmin solid lightgray;
    border-radius: 0.25vmin;
}

input.cell
{
    background-color: transparent;
}

input.cell.label
{
    opacity: 1;
    font-size: 1.5vmin;
    font-weight: bold;
    text-align: center;
}

input.cell:disabled
{
    background-color: transparent;
    pointer-events: none;
}

label.item-group-title
{
    color: #026FC273;
    font-size: 120%;
    font-weight: bold;
}