div.container
{
    align-items: flex-start;
    background-color: #d6e2e9;
    border: 0 solid #000000cc;
    border-left-width: 0.2vmin;
    border-right-width: 0.2vmin;
    border-top-width: 0.2vmin;
    color: #000000cc;
    border-radius: 1vmin;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-top: 0.25vmin;
    margin-right: 0.25vmin;
    margin-left: 0.25vmin;
    margin-bottom: 0;
    overflow: hidden;
}

/* functions */

div.container[type=add]
{
    background-color: #FFD6A5;
}

div.container[type=all]
{
    background-color: #FDFFB6;
}

div.container[type=and]
{
    background-color: #7161EF;
}

div.container[type=compare]
{
    background-color: #CAFFBF;
}

div.container[type=concat]
{
    background-color: #A0C4FF;
}

div.container[type=equals]
{
    background-color: #E07A5F;
}

div.container[type=exists]
{
    background-color: #FF99C8;
}

div.container[type=get]
{
    background-color: #BDB2FF;
}

div.container[type=if]
{
    background-color: #CDB4DB;
}

div.container[type=not]
{
    background-color: #52B788;
}

div.container[type=once]
{
    background-color: #8E9AAF;
}

div.container[type=or]
{
    background-color: #CDB4DB;
}

div.container[type=set]
{
    background-color: #FFC6FF;
}

div.container[type=varKey]
{
    background-color: #A2D2FF;
}


/* literals */

div.container[type=bool]
{
    background-color: #9BF6FF;
}

div.container[type=number]
{
    background-color: #9BF6FF;
}

div.container[type=string]
{
    background-color: #9BF6FF;
}

div.container[type=undefined]
{
    background-color: #9BF6FF;
}

div.header-container
{
    display: flex;
    flex: 0 1 10%;
}

div.body-container
{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
}

label.label
{
    font-size: 1.5vmin;
    line-height: 1.75vmin;
    margin-left: 0.25vmin;
}

label.label[bold]
{
    font-weight: bold;
}

div.input-container
{
    border-radius: 1vmin;
    margin: 0.25vmin;
}

.input
{
    border-radius: 1vmin;
    min-width: 3vmin;
}

div.delete
{
    flex: 0 1 10%;
    font-size: 1vmin;
}

div.container[hovered]
{
    z-index: 9999;
}

div.container[hovered]:not([literal])
{
    background-color: #e63946;

    -webkit-transition: background-color 0.5s linear;
    -ms-transition: background-color 0.5s linear;
    transition: background-color 0.5s linear;
}

div.container[literal][hovered]
{
    flex: 1 0 auto;

    max-width: 20vmin;
    -webkit-transition: max-width 0.5s ease 0s;
    -ms-transition: max-width 0.5s ease 0s;
    transition: max-width 0.5s ease 0s;
}

div.container[literal]:not([hovered])
{
    flex: 0 1 auto;

    max-width: 6vmin;
    -webkit-transition: max-width 0.5s ease 0s;
    -ms-transition: max-width 0.5s ease 0s;
    transition: max-width 0.5s ease 0s;
}
