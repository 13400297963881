/*
    Input
*/

.input
{
    border: 0.1vmin solid lightgray;
    border-radius: 0.25vmin;
    font-size: 2vmin;
    height: 100%;
    width: 100%;
}

.input::placeholder 
{
    color: #e63946;
    opacity: 0.8;
}

.input::-ms-input-placeholder
{
    color: #e63946;
}

.input[disabled]
{
    opacity: 0.6;
}