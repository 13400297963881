div.container
{
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.content-body
{
    display: inline-block;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;
}

.content-body[canselect='false']
{
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
}


/* .content-body[contenteditable]
{
    outline: 0.3vmin solid #d3d3d333; // TODO: figure out why editable sets outline: none
} */

img.image
{
    display: inline-block;
    max-height: 100%;
    max-width: 100%;
}