/*
    Number Input
*/

div.container
{
    border: 0.1vmin solid lightgray;
    border-radius: 0.25vmin;
    display: flex;
    flex-direction: row;
    height: 100%;
    overflow: hidden;
    position: relative;
    width: 100%;
}

div.container[disabled]
{
    opacity: 0.6;
}

div.container:hover:not([disabled]) div.controls-container
{
    opacity: 1;
}

div.input-container
{
    flex: 0 1 100%;
}

input.number
{
    border: none;
    font-size: 2vmin;
    height: 100%;
    padding-left: 0.5vmin;
    width: 100%;
}

div.controls-container
{
    align-items: stretch;
    background-color: #ffffffcc;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    max-width: 20vmin;
    opacity: 0;
    position: absolute;
    right: 0;
    transition: opacity 0.24s linear 0.1s;
    width: 30%;
}

div.arrow
{
    align-items: center;
    border-color: lightgray;
    border-style: solid;
    color: gray;
    display: flex;
    flex: 0 1 50%;
    font-size: 1vmin;
    justify-content: center;
    transition: all 0.1s linear;
}

div.arrow.down
{
    border-width: 0 0 0 0.1vmin;
}

div.arrow.up
{
    border-width: 0 0 0.1vmin 0.1vmin;
}

.arrow:hover
{
    flex: 0 1 70%
}