/*
    Examiner's session managment page
*/


div.card
{
    align-items: center;
    background-color: #FFFFFF;
    border-radius: 6px;
    box-shadow: 0 6px 9px 0 rgba(144,164,183,0.22);
    display: flex;
    flex-direction: column;
    height: 95%;
    justify-content: center;
    width: 95%;
}

div.input-container
{
    color: #8A96A0;
    display: flex;
    flex: 0 1 25%;
    
    justify-content: center;
    flex-direction: column;
}

div.item-button-container
{
    align-items: center;
    display: flex;
    flex: 0 1 20%;
    justify-content: center;
    flex-direction: column;
    width: 40%;
}

.item-button
{
    height: 40%;
    width: 100%;
}

div.list-container
{
    display: flex;
    flex: 0 1 90%;
    max-height: 90%;
    width: 90%;
}