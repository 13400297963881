div.container
{
    align-items: center;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    width: 100%;
}

div.header-container
{
    flex: 0 1 5%;
    margin-top: 1vmin;
}