@keyframes fadeIn {
    100% {
        opacity:1;
    }
}

@keyframes fadeOut {
    100% {
        opacity:0;
    }
}

body{
    background: #F9F9F9!important;
    overflow: hidden;
}

#root {
    height: 100%;
    width: 100%;
}

/* disable scrollbars */
.hide-scrollbars {
    overflow: -moz-scrollbars-none;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* IE 10+ */
}

.hide-scrollbars::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* Chrome/Safari/Webkit */
}

/* hide number input spinners */
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}

/*
    Ant Design global modifications
    These modifications are so atnd components may set their size as % of parent dimensions.
*/

/* collapse */

.ant-collapse {
    height: 100%;
    overflow-y: auto;
    width: 100%;
}

/* calendar picker */

.ant-calendar-picker-icon {
    font-size: 2vmin;
    margin-top: -1vmin;
    right: 1vmin;
}

.ant-calendar-picker-input {
    font-size: 1.5vmin;
    height: 100%;
    width: 100%;
}

.ant-btn-icon-only
{
    font-size: unset;
}

.ant-btn-icon-only > *
{
    font-size: unset;
}

/* input */
.ant-input {
    font-size: 2vmin;
}

.ant-picker-input > input
{
    font-size: inherit;
}

/* radio */

.ant-radio-wrapper {
    font-size: 2vmin;
}

/* select */

.ant-select {
    font-size: 1.75vmin;
}

.ant-select-arrow {
    font-size: 1.5vmin;
    margin-top: -0.5vmin;
}

.ant-select-selection--single {
    height: 100%;
    width: 100%;
}

.ant-select-selection__rendered {
    line-height: unset;
}

/* switch */
.ant-switch {
    height: 100%;
}