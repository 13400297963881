div.container
{
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
}

div.radio-container
{
    flex: 0 1 25%;
    height: 70%;
    margin-right: 1vmin;
}

div.input-container
{
    flex: 0 1 10%;
    height: 70%;
    margin-right: 1vmin;
}

label.name
{
    flex: 0 1 5%;
    font-size: 2vmin;
    height: 50%;
    margin-right: 1vmin;
    white-space: nowrap;
}

div.check-box-container
{
    align-items: center;
    display: flex;
    flex: 0 1 4%;
    height: 70%;
    justify-content: center;
    margin-right: 1vmin;
}