div.container
{
    display: flex;
    height: 100%;
    width: 100%;
}

div.container[marginright]
{
    margin-right: 0.5vmin;
}