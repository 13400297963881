/*
Examinee Test Page
*/

div.container
{
    align-items: center;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
    padding: 1vmin;
    width: 100vw;
}