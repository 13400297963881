/*
    a breadcrumb navigation component
*/

div.container {
    align-content: center;
    align-self: flex-start;
    display: flex;
    flex: 0 1 5%;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    justify-self: flex-start;
}

div.crumb__container {
    align-content: center;
    display: flex;
    flex: 0 1 1%;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
}

.crumb__link {
    font-size: 1.75vmin;
    margin: auto;
    margin-left: 0.5vmin;
    text-decoration: none;
    white-space: nowrap;
}

label.crumb__label {
    color: #35405280;
}

.crumb__icon {
    margin: auto;
    margin-left: 0.5vmin;
}